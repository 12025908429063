import React from "react"
import { string } from "prop-types"
import { Heading2 } from "../_globalSettings/typography"
import { StyledVideosLeft } from "./style"

const VideoLeft = ({ headline, content }) => {
	return (
		<StyledVideosLeft>
			<Heading2>{headline}</Heading2>
			<p>{content}</p>
		</StyledVideosLeft>
	)
}

export const VideoLeftProps = {
	headline: string.isRequired,
	content: string.isRequired,
}

VideoLeft.propTypes = {
	...VideoLeftProps,
}

export default VideoLeft
