import React from "react"
import { shape } from "prop-types"
import styled from "styled-components"
import { color } from "../components/_globalSettings/skin"
import { breakpoint } from "../components/_globalSettings/var"
import Layout, { layoutProps } from "../components/Layout"
import Row from "../components/Layout/Content/Row"
import VideoLeft from "../components/Videos/left"
import VideoRight from "../components/Videos/right"
import VideoList from "../components/Videos/list"

const VideosPageWrap = styled.div`
	display: flex;
	padding-top: 123px;
	@media (max-width: ${breakpoint.default.to}px) {
		padding-top: 40px;
	}
`

const VideosTopWrap = styled.div`
	display: flex;
	@media (max-width: ${breakpoint.default.to}px) {
		flex-direction: column;
	}
`

const VideoListWhiteBg = styled.div`
	width: 100%;
	height: 203px;
	background: #fff;
	position: absolute;
	top: 0;
`

const VideoListWrap = styled.div`
	position: relative;
`

const Education = ({ pageContext }) => {
	return (
		<Layout {...pageContext}>
			<Row>
				<VideosPageWrap>
					<VideosTopWrap>
						<VideoLeft {...pageContext.banner} />
						<VideoRight {...pageContext.pinnedVideo} />
					</VideosTopWrap>
				</VideosPageWrap>
			</Row>
			<VideoListWrap>
				<VideoListWhiteBg />
				<Row color={color.light.n700}>
					<VideoList {...pageContext.videosWithoutPinned} />
				</Row>
			</VideoListWrap>
		</Layout>
	)
}

Education.propTypes = {
	pageContext: shape({
		...layoutProps,
	}).isRequired,
}

export default Education
