import React from "react"
import { Link } from "gatsby"
import { string, shape, arrayOf, bool } from "prop-types"
import { StyledVideosRight } from "./style"
import { Heading5 } from "../_globalSettings/typography"
import SlidesLive from "../SlidesLive"

const VideoRight = ({ videoName, description, url, videoEmbedId }) => {
	return (
		<StyledVideosRight>
			<div className="video__wrapper">
				<SlidesLive key={url} presentationId={videoEmbedId} />
			</div>
			<Heading5>
				<Link to={`/zamereno-na-vyvoj/${url}`}>{videoName}</Link>
			</Heading5>
			<p>{description}</p>
		</StyledVideosRight>
	)
}

export const VideoRightProps = {
	nodes: arrayOf(
		shape({
			videoName: string.isRequired,
			videoEmbedId: string.isRequired,
			url: string.isRequired,
			seo: string.isRequired,
			description: string.isRequired,
			pinned: bool,
		})
	),
}

VideoRight.propTypes = {
	...VideoRightProps,
}

export default VideoRight
